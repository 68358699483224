import { profileActionType } from '../../constants';

const userReducer = (state = {}, action) => {
    switch (action.type) {
        case profileActionType.GET_PROFILE_INFO:
            return action.payload;
        case profileActionType.CHANGE_PROFILE_PASSWORD:
            return action.payload;
        default:
            return state;
    }
};
export default userReducer