
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useFormik } from "formik";
import { Eye, EyeOff } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { IconLoading } from "../../../../shared/common/Loading";
import { changePassword } from "../../../../store/actions/userAction";

const PasswordChangeModal = ({ show, onHide, data, setData, additionalDetails }) => {
    const dispatch = useDispatch();
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const initialFormData = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    };

    const passwordValidate = Yup.object().shape({
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            // .min(5, 'Confirm Password must be at least 5 characters')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    });

    const passwordFormik = useFormik({
        initialValues: initialFormData,
        validationSchema: passwordValidate,
        onSubmit: async (values) => {

            if (Object.keys(values)?.length > 0) {
                // api call base on condition
                try {
                    dispatch(changePassword({
                        data: {
                            oldPassword: values?.oldPassword,
                            newPassword: values?.newPassword
                        },
                        setLoading
                    }));

                    onHide();
                } catch (error) {
                }
            }
        }
    });

    const handleChange = (e) => {
        passwordFormik?.setFieldValue(e.target.name, e.target.value)
    }

    useEffect(() => {

        if (show && data !== null) {
            passwordFormik?.setValues({
                _id: data?._id,
                oldPassword: data?.oldPassword,
                newPassword: data?.newPassword,
                confirmPassword: data?.confirmPassword
            });

        } else {
            passwordFormik?.resetForm();
            // setData(null);
        }
    }, [show]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible)
    }


    return (
        <Dialog open={show} onOpenChange={onHide}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="text-center">Set a New password</DialogTitle>
                </DialogHeader>
                <form onSubmit={passwordFormik?.handleSubmit} className="grid grid-cols-1 gap-5">
                    <div className="">
                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                            Old Password:
                        </Label>
                        <Input
                            className={`  `}
                            id="oldPassword" type="text" name="oldPassword" placeholder="Old Password.." onChange={(e) => { handleChange(e) }} value={passwordFormik?.values?.oldPassword} />
                        {passwordFormik?.errors?.oldPassword && passwordFormik?.touched?.oldPassword && <span className='text-[12px] text-red'>{passwordFormik?.errors?.oldPassword}</span>}
                    </div>
                    <div className="relative">
                        <Label htmlFor="refKey" className="block mb-2.5 text-textV1 font-500">
                            New Password:
                        </Label>
                        <Input
                            className={`  `}
                            id="newPassword" type={passwordVisible ? "text" : "password"} name="newPassword" placeholder="New Password.." onChange={(e) => { handleChange(e) }} value={passwordFormik?.values?.newPassword} />
                        {passwordFormik?.errors?.newPassword && passwordFormik?.touched?.newPassword && <span className='text-[12px] text-red'>{passwordFormik?.errors?.newPassword}</span>}
                        <Button type="button" className="w-12 bg-transparent order-last py-2 px-0  hover:!bg-transparent border-s absolute right-0 top-[24px] rounded-ss-none rounded-es-none" onClick={togglePasswordVisibility}>
                            {passwordVisible ? <EyeOff className='text-black' /> : <Eye className='text-black' />}
                        </Button>
                    </div>
                    <div className="relative">
                        <Label htmlFor="refKey" className="block mb-2.5 text-textV1 font-500">
                            Confirm Password:
                        </Label>
                        <Input
                            id="confirmPassword" type={confirmPasswordVisible ? "text" : "password"} name="confirmPassword" placeholder="Confirm Password.." onChange={(e) => { handleChange(e) }} value={passwordFormik?.values?.confirmPassword} />
                        {passwordFormik?.errors?.confirmPassword && passwordFormik?.touched?.confirmPassword && <span className='text-[12px] text-red'>{passwordFormik?.errors?.confirmPassword}</span>}
                        <Button type="button" className="w-12 bg-transparent order-last py-2 px-0  hover:!bg-transparent border-s absolute right-0 top-[24px] rounded-ss-none rounded-es-none" onClick={toggleConfirmPasswordVisibility}>
                            {confirmPasswordVisible ? <EyeOff className='text-black' /> : <Eye className='text-black' />}
                        </Button>
                    </div>
                    <div className="flex justify-end items-center">
                        <Button disabled={loading} type="submit" className="h-auto px-[18px] py-[6px]">{loading ? <IconLoading /> : "Save"}</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}
export default PasswordChangeModal