import React, { useEffect, useState } from 'react'
import Logo from "../../assets/images/Logo.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux"
import { adminDashboardNavigation, dashboardNavigation } from '../../static/static';
import Divider from '../../components/common/Divider';
import IconLogout from '../../components/svg/IconLogout';
import { Button } from '../../components/ui/button';
import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { logout } from '../../store/actions/auth/login';
import IconLeftSide from '../../components/svg/IconLeftSide';
import IconDownSide from '../../components/svg/IconDownSide';
import { useMediaQuery } from 'react-responsive'
import Backdrop from '../../components/common/Backdrop';
import { Tokens } from '../../constants';

const DashboardSidebar = ({ toogleMiniSidebar, setToogleMiniSidebar, setToogleResponsiveSidebar, toogleResponsiveSidebar, logoutLoading, setLogoutLoading }) => {
    const [activeNestedNav, setActiveNestedNav] = useState(null)
    const role = (localStorage.getItem(Tokens.ADMIN_ROLE))
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const isLargeScreen = useMediaQuery({
        query: '(min-width: 992px)'
    })
    const handleLogout = () => {
        dispatch(logout({ navigate, setLogoutLoading }))
    }
    const handleNestedNavClick = (link) => {
        if (link !== activeNestedNav) {
            setActiveNestedNav(link)
        } else {
            setActiveNestedNav(null)
        }
    }

    useEffect(() => {
        const fullPath = pathname.trim().toLowerCase();
        const parts = fullPath.split("/");
        // Get the last part of the path
        const partName = parts[1];

        setActiveNestedNav(partName)
    }, [])

    useEffect(() => {
        if (toogleMiniSidebar) {
            setActiveNestedNav(null)
        }
    }, [toogleMiniSidebar])

    return (
        <aside onMouseEnter={() => setToogleMiniSidebar(false)} className={`fixed top-0 ${toogleResponsiveSidebar ? "start-0" : "-start-full"} lg:!start-0 transition-all duration-300 group ${toogleMiniSidebar && isLargeScreen ? "w-[80px] sidebar-mini" : "w-[260px]"} h-screen z-50 bg-theme`}>
            <Button className={"bg-themeV2 w-7 h-7 p-0 rounded-full absolute top-3 -end-3.5 z-30 lg:flex hidden"} onClick={() => setToogleMiniSidebar(!toogleMiniSidebar)}>
                <ChevronLeftIcon strokeWidth={"2.2"} className={`text-white w-5 h-5 transition-all duration-300 ${toogleMiniSidebar ? "rotate-180" : ""}`} />
            </Button>
            <div className="sidebar-header logo-part p-5 min-h-20 lg:block hidden">
                <Link to={"/"}>
                    <img src={Logo} alt='logo' className='transition-all w-full duration-300 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible group-[.sidebar-mini]:w-0 opacity-100 visible' />
                </Link>
            </div>
            {role === 'user' ? <div className="menu-part px-5 !pt-5 group-[.sidebar-mini]:px-3 transition-all duration-300 !py-0 lg:h-[calc(100%-150px)] h-[calc(100%-40px)] overflow-y-auto overflow-x-hidden scrollbar-w-thin">
                <div className="nav-part-1 pb-4">
                    <ul>
                        {dashboardNavigation.slice(0, 8).map(({ href, icon, link, childrenNavigation, hasChild }, index) => (
                            <li key={index}>
                                {
                                    !childrenNavigation?.length > 0 && !hasChild ? (
                                        <Link to={href} className={`py-4 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${(pathname === href && !pathname.includes('data')) || (link === 'Portfolio' && pathname.includes('portfolio')) || (link === 'Allocation' && pathname.includes('allocation')) ? "bg-themeV2/10 active" : ""}`}>
                                            <div className="flex justify-between items-center gap-4">
                                                <div className="flex items-center gap-4">
                                                    <span>
                                                        {icon}
                                                    </span>
                                                    <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                </div>
                                                <div>
                                                    {childrenNavigation ? pathname.includes('data') ? <IconDownSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : <IconLeftSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : ''}
                                                </div>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div className="">
                                            <button onClick={() => handleNestedNavClick(link.toLowerCase())} className={`py-4 w-full px-5 mb-1 group-[.sidebar-mini]:px-[19px] transition-all duration-300 block rounded-sm group ${(pathname.includes('data')) ? "!bg-themeV2/10 active" : ""}`}>
                                                <div className="flex justify-between items-center gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <span>
                                                            {icon}
                                                        </span>
                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                    </div>
                                                    <div>
                                                        <IconDownSide className={`w-5 h-5 text-white group-[.active]:text-themeV2 transition-all duration-200 ${activeNestedNav === link.toLowerCase() ? "rotate-180" : ""}`} />
                                                    </div>
                                                </div>
                                            </button>
                                            {
                                                <div className={`${activeNestedNav === link.toLowerCase() ? "transition-all duration-300 h-auto" : "transition-all duration-300 h-0 overflow-hidden"}`}>
                                                    <ul className='ml-6'>
                                                        {childrenNavigation && childrenNavigation?.map(({ href, icon, link }, index) => (
                                                            <li key={index}>
                                                                <Link to={href} onClick={(e) => e.stopPropagation()} className={`py-2 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${pathname === href || (link === 'Prices' && pathname.includes('prices')) ? "active" : ""}`}>
                                                                    <div className="flex items-center gap-4">
                                                                        <span>
                                                                            {icon}
                                                                        </span>
                                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </li>
                        ))}
                    </ul>
                </div>
                <Divider />
                <div className="pt-4">
                    <ul>
                        {dashboardNavigation.slice(8, 9).map(({ href, icon, link, childrenNavigation, hasChild }, index) => (
                            <li key={index}>
                                {
                                    !childrenNavigation?.length > 0 && !hasChild ? (
                                        <Link to={href} className={`py-4 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${(pathname === href && !pathname.includes('data')) || (link === 'Portfolio' && pathname.includes('portfolio')) || (link === 'Allocation' && pathname.includes('allocation')) ? "bg-themeV2/10 active" : ""}`}>
                                            <div className="flex justify-between items-center gap-4">
                                                <div className="flex items-center gap-4">
                                                    <span>
                                                        {icon}
                                                    </span>
                                                    <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                </div>
                                                <div>
                                                    {childrenNavigation ? pathname.includes('data') ? <IconDownSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : <IconLeftSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : ''}
                                                </div>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div className="">
                                            <button onClick={() => handleNestedNavClick(link.toLowerCase())} className={`py-4 mb-1 w-full px-5 group-[.sidebar-mini]:px-[19px] transition-all duration-300 block rounded-sm group ${(pathname.includes('account')) ? "!bg-themeV2/10 active" : ""}`}>
                                                <div className="flex justify-between items-center gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <span>
                                                            {icon}
                                                        </span>
                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                    </div>
                                                    <div>
                                                        <IconDownSide className={`w-5 h-5 text-white group-[.active]:text-themeV2 transition-all duration-200 ${activeNestedNav === link.toLowerCase() ? "rotate-180" : ""}`} />
                                                    </div>
                                                </div>
                                            </button>
                                            {
                                                <div className={`${activeNestedNav === link.toLowerCase() ? "transition-all duration-300 h-auto" : "transition-all duration-300 h-0 overflow-hidden"}`}>
                                                    <ul className='ml-6'>
                                                        {childrenNavigation && childrenNavigation?.map(({ href, icon, link }, index) => (
                                                            <li key={index}>
                                                                <Link to={href} onClick={(e) => e.stopPropagation()} className={`py-2 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${pathname === href ? "active" : ""}`}>
                                                                    <div className="flex items-center gap-4">
                                                                        <span>
                                                                            {icon}
                                                                        </span>
                                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </div> : <div className="menu-part px-5 !pt-5 group-[.sidebar-mini]:px-3 transition-all duration-300 !py-0 lg:h-[calc(100%-150px)] h-[calc(100%-40px)] overflow-y-auto overflow-x-hidden scrollbar-w-thin">
                <div className="nav-part-1 pb-4">
                    <ul>
                        {adminDashboardNavigation.slice(0, 1).map(({ href, icon, link, childrenNavigation, hasChild }, index) => (
                            <li key={index}>
                                {
                                    !childrenNavigation?.length > 0 && !hasChild ? (
                                        <Link to={href} className={`py-4 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${(pathname === href && !pathname.includes('data')) || (link === 'Portfolio' && pathname.includes('portfolio')) || (link === 'Allocation' && pathname.includes('allocation')) ? "bg-themeV2/10 active" : ""}`}>
                                            <div className="flex justify-between items-center gap-4">
                                                <div className="flex items-center gap-4">
                                                    <span>
                                                        {icon}
                                                    </span>
                                                    <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                </div>
                                                <div>
                                                    {childrenNavigation ? pathname.includes('data') ? <IconDownSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : <IconLeftSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : ''}
                                                </div>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div className="">
                                            <button onClick={() => handleNestedNavClick(link.toLowerCase())} className={`py-4 w-full px-5 mb-1 group-[.sidebar-mini]:px-[19px] transition-all duration-300 block rounded-sm group ${(pathname.includes('data')) ? "!bg-themeV2/10 active" : ""}`}>
                                                <div className="flex justify-between items-center gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <span>
                                                            {icon}
                                                        </span>
                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                    </div>
                                                    <div>
                                                        <IconDownSide className={`w-5 h-5 text-white group-[.active]:text-themeV2 transition-all duration-200 ${activeNestedNav === link.toLowerCase() ? "rotate-180" : ""}`} />
                                                    </div>
                                                </div>
                                            </button>
                                            {
                                                <div className={`${activeNestedNav === link.toLowerCase() ? "transition-all duration-300 h-auto" : "transition-all duration-300 h-0 overflow-hidden"}`}>
                                                    <ul className='ml-6'>
                                                        {childrenNavigation && childrenNavigation?.map(({ href, icon, link }, index) => (
                                                            <li key={index}>
                                                                <Link to={href} onClick={(e) => e.stopPropagation()} className={`py-2 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${pathname === href || (link === 'Prices' && pathname.includes('prices')) ? "active" : ""}`}>
                                                                    <div className="flex items-center gap-4">
                                                                        <span>
                                                                            {icon}
                                                                        </span>
                                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </li>
                        ))}
                    </ul>
                </div>
                <Divider />
                <div className="pt-4">
                    <ul>
                        {adminDashboardNavigation.slice(1, 2).map(({ href, icon, link, childrenNavigation, hasChild }, index) => (
                            <li key={index}>
                                {
                                    !childrenNavigation?.length > 0 && !hasChild ? (
                                        <Link to={href} className={`py-4 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${(pathname === href && !pathname.includes('data')) || (link === 'Portfolio' && pathname.includes('portfolio')) || (link === 'Allocation' && pathname.includes('allocation')) ? "bg-themeV2/10 active" : ""}`}>
                                            <div className="flex justify-between items-center gap-4">
                                                <div className="flex items-center gap-4">
                                                    <span>
                                                        {icon}
                                                    </span>
                                                    <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                </div>
                                                <div>
                                                    {childrenNavigation ? pathname.includes('data') ? <IconDownSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : <IconLeftSide className="w-5 h-5 text-white group-[.active]:text-themeV2" /> : ''}
                                                </div>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div className="">
                                            <button onClick={() => handleNestedNavClick(link.toLowerCase())} className={`py-4 mb-1 w-full px-5 group-[.sidebar-mini]:px-[19px] transition-all duration-300 block rounded-sm group ${(pathname.includes('account')) ? "!bg-themeV2/10 active" : ""}`}>
                                                <div className="flex justify-between items-center gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <span>
                                                            {icon}
                                                        </span>
                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                    </div>
                                                    <div>
                                                        <IconDownSide className={`w-5 h-5 text-white group-[.active]:text-themeV2 transition-all duration-200 ${activeNestedNav === link.toLowerCase() ? "rotate-180" : ""}`} />
                                                    </div>
                                                </div>
                                            </button>
                                            {
                                                <div className={`${activeNestedNav === link.toLowerCase() ? "transition-all duration-300 h-auto" : "transition-all duration-300 h-0 overflow-hidden"}`}>
                                                    <ul className='ml-6'>
                                                        {childrenNavigation && childrenNavigation?.map(({ href, icon, link }, index) => (
                                                            <li key={index}>
                                                                <Link to={href} onClick={(e) => e.stopPropagation()} className={`py-2 px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group ${pathname === href ? "active" : ""}`}>
                                                                    <div className="flex items-center gap-4">
                                                                        <span>
                                                                            {icon}
                                                                        </span>
                                                                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>{link}</h5>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </div>}
            <div className="logout-part px-5 group-[.sidebar-mini]:px-3 transition-all duration-300">
                <Link to={""} className={`px-5 group-[.sidebar-mini]:px-[19px]  block rounded-sm group`}>
                    <div className="flex items-center gap-4" onClick={() => handleLogout()}>
                        <span>
                            <IconLogout className="text-white w-5 h-5" />
                        </span>
                        <h5 className='text-sm font-500 text-white group-[.active]:text-themeV2 group-[.sidebar-mini]:opacity-0 group-[.sidebar-mini]:invisible opacity-100 visible transition-all duration-300'>Logout</h5>
                    </div>
                </Link>
            </div>
        </aside>
    )
}

export default DashboardSidebar