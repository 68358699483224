import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import apiConfig from "@/config/apiConfig"

const PaginatedSelect = ({
    apiEndpoint,
    params = {},
    isDisabled,
    onSelectionChange,
    placeholder,
    initialData = [],
}) => {
    const [data, setData] = useState(initialData);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [isFirst, setIsFirst] = useState(false);

    // Fetch data whenever search value or page changes
    useEffect(() => {
        if (searchVal !== '' || isFirst) {
            setPage(1);
            setHasMore(true);
            setData([]);
            fetchData(searchVal);
        }
    }, [searchVal]);

    // Fetch data function
    const fetchData = async (searchQuery = '') => {
        try {
            if (hasMore && !isDisabled) {
                setLoading(true);
                const response = await apiConfig.get(`${apiEndpoint}`, {
                    params: {
                        ...params,
                        page: page,
                        name: searchQuery,
                    },
                });

                const fetchedData = response?.data?.data?.records || [];
                const options = fetchedData?.length > 0 ? fetchedData?.map((item) => ({
                    value: item?._id,
                    label: item?.description || "No Label",
                    slug: item?.description,
                })) : [];

                setData((prevData) => [...prevData, ...options]);
                setHasMore(fetchedData.length > 0); // Set to false if no more data
                setPage((prevPage) => prevPage + 1);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    // Handle item selection
    const handleSelectItem = (selectedOption, action) => {
        setSelectedItems(selectedOption || []);
        onSelectionChange(selectedOption, action);
    };

    // Handle input change for search
    const handleInputChange = (value) => {
        if (value === '' && isFirst) {
            resetData();
        } else {
            setSearchVal(value);
        }
    };

    // Reset data when needed (for clearing or first load)
    const resetData = () => {
        setPage(1);
        setHasMore(true);
        setData([]);
        fetchData('');
    };

    console.log(data)

    return (
        <div className="custom-dropdown">
            <Select
                options={data}
                value={selectedItems}
                className="react_selector"
                onChange={(selectedOption, action) => handleSelectItem(selectedOption, action)}
                onInputChange={handleInputChange}
                isDisabled={isDisabled}
                isMulti
                isLoading={loading}
                onMenuScrollToBottom={() => hasMore && fetchData(searchVal)} // Trigger pagination on scroll
                onMenuOpen={() => fetchData(searchVal)} // Fetch data when menu opens
                placeholder={placeholder || 'Select an option'}
                styles={{
                    groupHeading: (base) => ({ color: 'black' }),
                    menuList: (base) => ({
                        fontSize: '0.75rem',
                        overflowY: 'auto',
                        overflowWrap:"break-word",
                        overflowX:"hidden",
                        maxHeight: '180px',
                        lineHeight: '1rem',
                        fontWeight: 500,
                        borderRadius: '5px',
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                        ...base,
                        cursor: 'pointer',
                        borderRadius: '5px',
                        margin: '0px 5px',
                        backgroundColor: isSelected ? 'rgb(215 235 255 / 1)' : 'transparent',
                        color: isSelected ? 'hsla(210, 100%, 12%, 1)' : 'hsla(210, 100%, 12%, 1)',
                        ':hover': {
                            backgroundColor: isFocused ? '#d7ebff' : 'transparent',
                        },
                    }),
                }}
            />
        </div>
    );
};

export default PaginatedSelect;
