
import { apiBaseURL, profileActionType, toastifyType, } from "../../constants";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";


export const getProfileInfo = ({ setLoading }) => async (dispatch) => {
  try {
    setLoading && setLoading(true);
    const response = await apiConfig.get(`${apiBaseURL.USER}/info`);
    dispatch({ type: profileActionType.GET_PROFILE_INFO, payload: response?.data?.data });
    setLoading && setLoading(false);
  } catch (error) {
    setLoading && setLoading(false);
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
  }
};


export const changePassword = ({ data, setLoading }) => async (dispatch) => {
  try {
    setLoading && setLoading(true);
    const response = await apiConfig.post(`${apiBaseURL.USER}/change-password`, data);
    dispatch({ type: profileActionType.GET_PROFILE_INFO, payload: response?.data?.data });
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
    dispatch(getProfileInfo({ setLoading }))
    setLoading && setLoading(false);
  } catch (error) {
    setLoading && setLoading(false);
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
  }
};