const countryDateLocales = [
    { label: "United States", value: "en-US", alpha2Code: "US" },
    { label: "United Kingdom", value: "en-GB", alpha2Code: "GB" },
    { label: "Canada (English)", value: "en-CA", alpha2Code: "CA" },
    { label: "Australia", value: "en-AU", alpha2Code: "AU" },
    { label: "India", value: "en-IN", alpha2Code: "IN" },
    { label: "Germany", value: "de-DE", alpha2Code: "DE" },
    { label: "France", value: "fr-FR", alpha2Code: "FR" },
    { label: "China", value: "zh-CN", alpha2Code: "CN" },
    { label: "Japan", value: "ja-JP", alpha2Code: "JP" },
    { label: "Brazil", value: "pt-BR", alpha2Code: "BR" },
    { label: "Russia", value: "ru-RU", alpha2Code: "RU" },
    { label: "Mexico", value: "es-MX", alpha2Code: "MX" },
    { label: "Italy", value: "it-IT", alpha2Code: "IT" },
    { label: "Spain", value: "es-ES", alpha2Code: "ES" },
    { label: "South Korea", value: "ko-KR", alpha2Code: "KR" },
    { label: "South Africa", value: "en-ZA", alpha2Code: "ZA" },
    { label: "Sweden", value: "sv-SE", alpha2Code: "SE" },
    { label: "Netherlands", value: "nl-NL", alpha2Code: "NL" },
    { label: "Argentina", value: "es-AR", alpha2Code: "AR" },
    { label: "Saudi Arabia", value: "ar-SA", alpha2Code: "SA" }
];

export default countryDateLocales;