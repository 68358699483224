


import apiConfig from '../../config/apiConfig';
import { apiBaseURL, assetsAuthorizeActionType, assetsDetailsV2ActionType, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { metaDataAction } from './metaDataAction';
import { addTost } from './toastAction';


// export const fetchAssetsDetails = (setIsLoading) => async (dispatch) => {
//     try {
//         const response = await apiConfig.get(apiBaseURL.ASSETS_DETAILS + '/v2')
//         dispatch({ type: assetsDetailsV2ActionType.FETCH_ASSETS_DETAILS_V2, payload: response.data?.data });
//         dispatch(setLoading(false))
//         setIsLoading && setIsLoading(false)
//     } catch (error) {
//         dispatch(setLoading(false))
//         setIsLoading && setIsLoading(false)
//         dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
//     }
// }

export const fetchAssetsDetails = (setIsLoading, setAssetsData, page, perPage) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.ASSETS_DETAILS + '?page='+page+ '&perPage='+perPage)
        dispatch({ type: assetsDetailsV2ActionType.FETCH_ASSETS_DETAILS_V2, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: perPage, totalItems: response.data?.data?.totalRecords }))
        setAssetsData && setAssetsData(response.data?.data)
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}
export const fetchAssetsReportingDetails = (setIsLoading, setAssetsData, from) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.ASSETS_DETAILS + '?from='+from)
        dispatch({ type: assetsDetailsV2ActionType.FETCH_ASSETS_DETAILS_V2, payload: response.data?.data });
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const addAssets = (data, setAssetsData, currentPage, setCurrentPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ASSETS_DETAILS + '/add', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAssetsDetails(null, setAssetsData, currentPage, 10))
        setCurrentPage && setCurrentPage(currentPage)
        return response
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message ? error?.data?.message : error?.response?.data?.message }))
    }
}
export const updateAssets = (data, setAssetsData, currentPage, setCurrentPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ASSETS_DETAILS + '/update', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAssetsDetails(null, setAssetsData, currentPage || 1, 10))
        setCurrentPage && setCurrentPage(currentPage || 1)
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message ? error?.data?.message : error?.response?.data?.message }))
    }
}

export const fetchAuthorize = (id, setIsLoading) => async (dispatch) => {
    // dispatch(setLoading(true))
    setIsLoading && setIsLoading(true)
    try {
        const response = await apiConfig.get(apiBaseURL.ASSETS_DETAILS + '/' + id + '/portfolio')
        // dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch({ type: assetsAuthorizeActionType.FETCH_ASSETS_AUTHORIZE_V2, payload: response.data?.data });
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchAuthorize2 = (id, setIsLoading, currentPage, perPage, setTotalRecords, setTotalPages) => async (dispatch) => {
    // dispatch(setLoading(true))
    setIsLoading && setIsLoading(true)
    try {
        const response = await apiConfig.get(apiBaseURL.ASSETS_DETAILS + '/' + id + '/portfolio' + '?page=' + currentPage + '&perPage=' + perPage)
        // dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch({ type: assetsAuthorizeActionType.FETCH_ASSETS_AUTHORIZE_V2, payload: response.data?.data });
        dispatch(setLoading(false))
        setTotalRecords && setTotalRecords(response.data?.data?.totalRecords)
        setTotalPages && setTotalPages(response.data?.data?.totalPage)
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const removeAssetsDetails = (data, id, setAssetsData, metaData) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ASSETS_DETAILS + '/remove', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAuthorize(id))
       dispatch(fetchAssetsDetails(null, setAssetsData, metaData?.pageNumber, metaData?.pageSize))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}
export const unAuthorizeAssetsDetails = (data, id, setAssetsData, currentPage, itemsPerPage) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await apiConfig.post(apiBaseURL.ASSETS_DETAILS + '/remove-authorise', data);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchAuthorize(id, setAssetsData));
        dispatch(fetchAssetsDetails(null, setAssetsData, currentPage, itemsPerPage))
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    }
};
export const updateAssetsDetails = (data) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.put(apiBaseURL.ASSETS_DETAILS + '/update', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAssetsDetails(null, null, 1, 5))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}

export const authorize = (data, id, setAssetsData, currentPage, itemsPerPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ASSETS_DETAILS + '/authorise', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAuthorize(id, setAssetsData))
        dispatch(fetchAssetsDetails(null, setAssetsData, currentPage, itemsPerPage))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}



export const fetchReportsDetails =
    (
        chartReport,
        setChartData,
        chartType,
        setIsLoading,
        isAutoChart,
        indicator,
        portfolioId,
        chartData,
        changeChartDate
    ) =>
        async (dispatch) => {
            try {
                const response = await apiConfig.post(apiBaseURL.REPORTS, chartReport)
                const { data } = response.data
                setIsLoading(true)
                if (changeChartDate) {
                    changeDate(data, chartData, setChartData, setIsLoading, dispatch)
                } else {
                    createChart(data, chartReport, chartData, setIsLoading, setChartData, chartType, isAutoChart, dispatch)
                }

            } catch (error) {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
            }
        };


// create chart
const createChart = (data, chartReport, chartData, setIsLoading, setChartData, chartType, isAutoChart, dispatch) => {
    if (data.length) {
        let updateData = [];
        for (let index = 0; index < data.length; index++) {
            updateData.push({
                analytics: data[index].analytics,
                type: chartType,
                data: [data[index]],
                isAutoChart: isAutoChart,
                portfolio: chartReport[0].portfolio,
                chartId: data[index].chartId
            });
        }
        setChartData([...data, ...chartData])
        // dispatch(getAllReports(setChartData));
        // const prepareData = [...updateData, ...chartData]
        // dispatch(saveReports(prepareData, setChartData));
        setIsLoading(false)
    } else {
        const updateData = [{
            analytics: chartReport.reports[0].analytics,
            type: chartType,
            data: data,
            isAutoChart: isAutoChart,
            portfolio: chartReport.reports[0].portfolio,
            chartId: data.chartId
        }]
        setChartData([...data, ...chartData])
        // dispatch(getAllReports(setChartData));
        // const prepareData = [...updateData, ...chartData]
        // dispatch(saveReports(prepareData, setChartData))
        setIsLoading(false)
    }
}

const changeDate = (data, chartData, setChartData, setIsLoading, dispatch) => {
    const updateData =
        chartData &&
        chartData.length &&
        chartData.map((item, index) => {
            let oldIndex = index;
            item.data = data.filter((x, index) => index === oldIndex);
            return item;
        });
    setChartData([...data])
    // dispatch(getAllReports(setChartData));
    // dispatch(saveReports(updateData, setChartData));
    setIsLoading(false);
}