import React, { useEffect, useState } from 'react'
import ProfileComponent from './ProfileComponent'
import { useDispatch, useSelector } from 'react-redux'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import { getProfileInfo } from '../../../store/actions/userAction'

const Profile = () => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state?.user_profile)
  const [profileInfo, setProfileInfo] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getProfileInfo({ setLoading }))
  }, []);


  useEffect(() => {

    setProfileInfo(profile)
  }, [profile])
  return (
    <DashboardMaster>
      <ProfileComponent profileInfo={profileInfo} />
    </DashboardMaster >
  )
}

export default Profile