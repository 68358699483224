import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import React from 'react'
import PasswordChangeModal from './_model/PasswordChangeModal'
import Loading from '../../../shared/common/Loading'
import { Card, CardContent } from '../../../components/ui/Card'


const ProfileComponent = (props) => {

    const { profileInfo } = props;
    const [showPasswordChangeModel, setShowPasswordChangeModel] = React.useState(false);
    return (
        <div className='p-4'>
            <Card>
                <CardContent className="p-4 bg-white rounded-md">
                    <div className='flex justify-start items-center'>
                        <div className='rounded-full h-[150px] w-[150px] border  overflow-hidden'>
                            <img
                                src='/assets/images/user-profile.png'
                                className='h-full w-full bg-fit bg-no-repeat scale-110 bg-center hover:scale-125 duration-200 '
                                loading="lazy"
                                onLoad={() => <Loading />}
                                alt='preview-user'
                            />
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-4 p-3'>
                        <div className='flex justify-start items-center gap-2'>
                            <Label>Full Name :</Label>
                            <p className='text-sm'>{profileInfo?.name || 'Not Found '}</p>
                            {/* <Input  /> */}
                        </div>
                        <div className='flex justify-start items-center gap-2'>
                            <Label>Organization :</Label>
                            <p className='text-sm'>{profileInfo?.organizationName || "No Organization Found"}</p>
                        </div>
                        <div className='flex justify-start items-center gap-2'>
                            <Label>Email :</Label>
                            <p className='text-sm'>{profileInfo?.email}</p>
                        </div>
                        <div className='flex justify-start items-center gap-2'>
                            <Label>Last Login :</Label>
                            <p className='text-sm'>{new Date(profileInfo?.loginDate).toLocaleDateString("en-GB")}</p>
                        </div>
                        {/* <div className='flex justify-start items-center gap-2'>
                            <Label>Plan Type :</Label>
                            <p className='text-sm'>{profileInfo?.plan || "basic"}</p>
                        </div> */}
                    </div>

                    <div className='flex justify-end items-center gap-2'>
                        <Button onClick={() => setShowPasswordChangeModel(true)} >
                            Change Password
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <PasswordChangeModal show={showPasswordChangeModel} onHide={() => setShowPasswordChangeModel(false)} />
        </div>
    )
}

export default ProfileComponent;