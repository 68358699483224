import * as React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers';
import DownloadChart from '../../shared/DownloadChart';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, ToolboxComponent, SVGRenderer]);


export default function StackedAreaChart(props) {
  const { analyticReports, updateId, isAutoChart, isReports, index, refEl } = props;

  // deployment commit

  const [chartData, setChartData] = React.useState([])

  React.useEffect(() => {
    if (updateId === null && isReports === null) {
      setChartData([])
    } else {
      setChartData(analyticReports)
    }
  }, [analyticReports])


  let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
  let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];
  const labels = dateLabels ? dateLabels : '';
  let data
  if (isReports) {
    data = {
      labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
      datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
        return {
          label: item.label,
          data: item.data.map((value) => parseFloat(value)),
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 206, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 159, 64, 0.5)',
          ],
          borderWidth: 1,
        }
      }) : []
    }
  } else {
    data = {
      labels,
      datasets: [
        {
          label: labels,
          data: chartValues.map((value) => parseFloat(value)),
          fill: true,
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(255, 159, 64, 0.5)',
            'rgba(255, 205, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 0, 223, 0.5)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(255, 0, 223)'
          ],
          borderWidth: 1
        },
      ],
    };
  }

  const chartRef = React.useRef(null)
  const transformedData = data.datasets.map((dataset) => ({
    id: dataset.label,
    color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
    data: dataset.data.map((value, index) => ({
      x: data.labels[index],
      y: parseInt(Number(value)),
    })),
  }));

  const transformedChartData = transformedData.flatMap(entry =>
    entry.data.map(dataPoint => ({
      name: `${entry.id} - ${dataPoint.x}`,
      value: parseInt(Number(dataPoint.y))
    }))
  );

  const optionS = {
    tooltip: {
      trigger: 'axis',
      axis: 'auto',
      axisPointer: { type: 'shadow' },
      displayMode: 'single',
      renderMode: 'auto',
      displayMode: 'single',
      renderMode: 'auto',
      // Confining tooltip to the body
      confine: true,
      align: 'center',
      backgroundColor: '#001F3D',
      appendToBody: true,
      textStyle: {
        fontSize: 12, // Adjust the font size as needed
        color: '#FFFFFF',
      }
    },
    legend: {
      type: 'scroll',
      // orient: 'vertical',
      right: 0,
      top: 0,
      left: 0,
      // bottom: 20,
    },
    xAxis: [{
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: data.labels
    }],
    yAxis: [{
      type: 'value',
      // name: 'Value',
      position: 'left'
    }],
    toolbox: {
      feature: {
        saveAsImage: { show: true, title: 'save' },
        type: 'png'
        // magicType: { show: true, type: ['line', 'bar'] },
        // dataView: { show: true, readOnly: false },
        // restore: { show: true }
      },
      top: 20
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    series: transformedData.map(dataset => ({
      name: dataset.id,
      type: 'line',
      smooth: true,
      data: dataset.data.map(dataPoint => dataPoint.y)
    }))
  };

  // Predefined RGB colors
  const rgbColors = [
    'rgb(128, 255, 165)',
    'rgb(0, 221, 255)',
    'rgb(55, 162, 255)',
    'rgb(255, 0, 135)',
    'rgb(255, 191, 0)'
  ];
  // Function to get the next RGB color from the array
  let currentIndex = 0;
  function getNextRgbColor() {
    const color = rgbColors[currentIndex];
    currentIndex = (currentIndex + 1) % rgbColors.length;
    return color;
  }
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      axis: 'auto',
      // axisPointer: { type: 'shadow' },
      displayMode: 'single',
      renderMode: 'auto',
      displayMode: 'single',
      renderMode: 'auto',
      // // Confining tooltip to the body
      confine: true,
      // align: 'center',
      backgroundColor: '#001F3D',
      // appendToBody: true,
      textStyle: {
        fontSize: 12, // Adjust the font size as needed
        color: '#FFFFFF',
      }
    },
    legend: {
      type: 'scroll',
      // orient: 'vertical',
      right: 0,
      top: 0,
      left: 0,
      // data: data.labels // Assuming data.labels contain the legend data
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   },
    //   top: 20
    // },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        data: data.labels // Assuming data.labels contain the x-axis data
      }
    ],
    yAxis: [
      {
        // type: 'value',
        position: 'left'
      }
    ],
    series: transformedData.map((dataset, index) => ({
      name: dataset.id,
      type: 'line',
      smooth: true,
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      label: {
        show: true,
        position: 'top'
      },
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: rgbColors[index % rgbColors.length] },
          { offset: 1, color: rgbColors[index % rgbColors.length] }
        ])
      },
      emphasis: {
        focus: 'series'
      },
      data: dataset.data.map(dataPoint => dataPoint.y)
    }))
  };

  // Function to generate a random RGB color
  function getRandomColor() {
    const r = parseInt(Math.floor(Math.random() * 256));
    const g = parseInt(Math.floor(Math.random() * 256));
    const b = parseInt(Math.floor(Math.random() * 256));
    return `rgb(${r}, ${g}, ${b})`;
  }
  return (
    <>
      {data.datasets.length && data.labels.length ?
        <>
          <DownloadChart chartRef={refEl} chartData={data} chartId={"echarts-chart" + index} echarts={echarts} />
          <ReactEChartsCore
            className={"echarts-chart" + index  + ' ' + 'css-13aj3tc-MuiChartsSurface-root'}
            ref={refEl}
            echarts={echarts}
            style={{ width: '100%', height: '100%' }}
            option={option}
          />

        </> : <> <div>No Data Available</div>  </>}

    </>

  );
}


// =====================================================

// import * as React from 'react';
// import { LineChart } from '@mui/x-charts/LineChart';
// import DownloadChart from '../../shared/DownloadChart';

// const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
// const amtData = [2400, 2210, 0, 2000, 2181, 2500, 2100];
// const xLabels = [
//   'Page A',
//   'Page B',
//   'Page C',
//   'Page D',
//   'Page E',
//   'Page F',
//   'Page G',
// ];

// export default function StackedAreaChart(props) {
//   const { analyticReports, updateId, isAutoChart, isReports, refEl } = props;


//   const [chartData, setChartData] = React.useState([])

//   React.useEffect(() => {
//     if (updateId === null && isReports === null) {
//       setChartData([])
//     } else {
//       setChartData(analyticReports)
//     }
//   }, [analyticReports])


//   let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
//   let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];
//   const labels = dateLabels ? dateLabels : '';
//   let data
//   if (isReports) {
//     data = {
//       labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
//       datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
//         return {
//           label: item.label,
//           data: item.data.map((value) => parseFloat(value)),
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.5)',
//             'rgba(54, 162, 235, 0.5)',
//             'rgba(255, 206, 86, 0.5)',
//             'rgba(75, 192, 192, 0.5)',
//             'rgba(153, 102, 255, 0.5)',
//             'rgba(255, 159, 64, 0.5)',
//           ],
//           borderWidth: 1,
//         }
//       }) : []
//     }
//   } else {
//     data = {
//       labels,
//       datasets: [
//         {
//           label: labels,
//           data: chartValues.map((value) => parseFloat(value)),
//           fill: true,
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.5)',
//             'rgba(255, 159, 64, 0.5)',
//             'rgba(255, 205, 86, 0.5)',
//             'rgba(75, 192, 192, 0.5)',
//             'rgba(54, 162, 235, 0.5)',
//             'rgba(153, 102, 255, 0.5)',
//             'rgba(255, 0, 223, 0.5)'
//           ],
//           borderColor: [
//             'rgb(255, 99, 132)',
//             'rgb(255, 159, 64)',
//             'rgb(255, 205, 86)',
//             'rgb(75, 192, 192)',
//             'rgb(54, 162, 235)',
//             'rgb(153, 102, 255)',
//             'rgb(255, 0, 223)'
//           ],
//           borderWidth: 1
//         },
//       ],
//     };
//   }

//   const chartRef = React.useRef(null)

//   return (
//     <>
//       {data.datasets.length && data.labels.length ?
//         <>
//           <DownloadChart chartData={data} chartRef={refEl} />
//           <LineChart
//           ref={refEl}
//             width={500}
//             height={300}
//             series={data.datasets.map((dataset, index) => ({
//               data: dataset.data,
//               label: dataset.label,
//               area: true,
//               stack: 'total',
//               showMark: false,
//             }))}
//             xAxis={[{ scaleType: 'point', data: data.labels }]}
//             sx={{
//               '.MuiLineElement-root': {
//                 display: 'none',
//               },
//             }}
//           /></> : <div>No Data Available</div> }
//     </>
//   );
// }


// =====================================================

{/* <LineChart
          ref={refEl}
          width={500}
          height={300}
          series={[
            { data: uData, label: 'uv', area: true, stack: 'total', showMark: false },
            { data: pData, label: 'pv', area: true, stack: 'total', showMark: false },
            {
              data: amtData,
              label: 'amt',
              area: true,
              stack: 'total',
              showMark: false,
            },
          ]}
          xAxis={[{ scaleType: 'point', data: xLabels }]}
          sx={{
            '.MuiLineElement-root': {
              display: 'none',
            },
          }}
        /> */}