import React, { useState } from 'react'
import DashboardHeader from './DashboardHeader'
import DashboardSidebar from './DashboardSidebar'
import Backdrop from '../../components/common/Backdrop'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import Loading from '../../shared/common/Loading'

const DashboardMaster = ({ children }) => {
    const [toogleMiniSidebar, setToogleMiniSidebar] = useState(false);
    const [toogleResponsiveSidebar, setToogleResponsiveSidebar] = useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false)
    return (
        <div className='dashboard-layout bg-backgroundV1 min-h-screen'>
            <DashboardHeader toogleResponsiveSidebar={toogleResponsiveSidebar} setToogleResponsiveSidebar={setToogleResponsiveSidebar} toogleMiniSidebar={toogleMiniSidebar} />
            <DashboardSidebar setToogleResponsiveSidebar={setToogleResponsiveSidebar} toogleResponsiveSidebar={toogleResponsiveSidebar} setToogleMiniSidebar={setToogleMiniSidebar} toogleMiniSidebar={toogleMiniSidebar} logoutLoading={logoutLoading} setLogoutLoading={setLogoutLoading} />
            <main className={`dashboard-layout-main transition-all duration-300 ${toogleMiniSidebar ? "lg:ps-[80px]" : "lg:ps-[260px]"} pt-[56px] min-h-[inherit]`}>
                <div className="dashboard-pages-wrapper relative">
                    <section className='p-2 md:px-5'>
                        <Breadcrumbs/>
                    </section>
                    {children}
                    {logoutLoading && <div className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'><Loading /></div>}
                </div>
            </main>
            <Backdrop onClose={() => setToogleResponsiveSidebar(false)} show={toogleResponsiveSidebar} />
        </div>
    )
}

export default DashboardMaster 