import React, { useContext } from "react";
import Card from "./Card";
import Divider from "./Divider";
import { PricesContext } from "../../pages/dashboard/prices/details-page/PricesDetailsPage";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import Loading from "../../shared/common/Loading";

const PriceDetailCard = () => {
  const { financePrice } = useContext(PricesContext);
  const { isLoading } = useSelector((state) => state);
  const priceInfoLocalStorageData = JSON.parse(localStorage.getItem("priceInfo")) || null;
  return (
    <Card className="p-4 mb-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="">
            <h4 className="text-sm text-textV2 font-500 mb-1">{`${financePrice?.publicAsset?.name
                ? financePrice?.publicAsset?.name
                : ""
              } ${priceInfoLocalStorageData?.financeInstrument !== "BOND" ? `(${financePrice?.publicAsset?.symbol
                  ? financePrice?.publicAsset?.symbol
                  : ""
                })` : ''}`}</h4>
            <p className="text-xs font-300 text-[#909090]">{`${financePrice?.publicAsset?.exchange?.name
                ? financePrice?.publicAsset?.exchange?.name
                : ""
              } EOD Prices. Currency in  ${financePrice?.publicAsset?.currency
                ? financePrice?.publicAsset?.currency
                : ""
              }`}</p>
          </div>
          <Divider className={"my-3 border-borderV1"} />
          <div className="flex items-end justify-between">
            <div className="px-5 py-2.5 bg-themeV2/10 rounded-sm">
              {financePrice?.publicAsset?.adjClose === "Fetching" ? (
                <>
                  <span className="text-themeV2 text-2xl font-600">
                    <ThreeDots
                      visible={true}
                      height="35"
                      width="35"
                      color="#001F3D"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </span>
                </>
              ) : (
                <span className="text-themeV2 text-2xl font-600">
                  {financePrice?.publicAsset?.adjClose}
                </span>
              )}
            </div>
            <span className="text-xs font-300 text-[#909090]">{`At Date: ${new Date(
              financePrice?.publicAsset?.adjCloseDate
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })} `}</span>
          </div>
        </>
      )}
    </Card>
  );
};

export default PriceDetailCard;
